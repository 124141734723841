<template>
	<div>
	  <!-- <ITCSpinner :loaded="loaded"></ITCSpinner> -->
	  <div v-if="site">
		<div class="d-flex justify-content-center align-items-center pb-6 pt-3">
		  <DateRangeSelector
			v-if="!error"
			@update="dateRangeChanged"
			:loaded="loaded"
		  ></DateRangeSelector>
		</div>
  
		<div
		  v-if="starlinkIds.includes(Number(id))"
		  class="d-flex justify-content-center align-items-center pb-6"
		>
		  <input
			@click="openStarlinkMonitoring"
			class="btn btn-primary mb-2"
			type="button"
			value="Starlink Monitoring Details - click here"
		  />
		</div>
  
		<div v-if="error">
		  <h4>
			<b-alert show variant="info">
			  <i
				class="fa fa-exclamation-circle fa-circle"
				aria-hidden="true"
				style="
				  color: red;
				  background-image: radial-gradient(at center, white 40%, transparent 40%);
				"
			  ></i>
			  {{ errorMessage }}
			  <router-link
				:to="{ name: 'admineditsite', params: { id: $route.params.id } }"
				class="error-site-link"
			  >
				Click here to edit this site.
			  </router-link>
			</b-alert>
		  </h4>
		</div>
  
		<div
		  class="row widget-row"
		  v-for="(row, rindex) in site.layout.bootlayout"
		  :key="rindex"
		>
		  <div
			v-for="(col, cindex) in row"
			:key="cindex"
			:class="['col-md-' + (12 * col.sizeX / 4), 'margin-bottom-20']"
			style="padding-left: 15px; padding-right: 15px;"
		  >
			<component
			  v-if="widgets[col.WidgetArrId]"
			  :is="widgets[col.WidgetArrId].name"
			  v-bind="widgets[col.WidgetArrId].props"
			></component>
		  </div>
		</div>
  
		<div class="row widget-row" v-if="site">
		  <div class="col-md-12">
			<SiteTicket :site="site"></SiteTicket>
		  </div>
		</div>
	  </div>
  
	  <Loader
		v-if="loaderEnabled"
		:logo="loaderLogo"
		:spinnerClass="'spinner-light'"
	  ></Loader>
  
	  <ITCSpinner :loaded="loaded"></ITCSpinner>
	</div>
  </template>

<script>
import { mapGetters } from 'vuex';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';
import { GET_SITE_ALERT_BANNER, CLEAR_BANNER } from '@/core/services/store/notification.module.js';
import ITCSpinner from '@/view/content/loaders/itcSpinner.vue';

const component_names = [
	{ widgetId: 1, name: 'IdirectModemStatus' },
	{ widgetId: 2, name: 'IdirectGraphs' },
	{ widgetId: 3, name: 'SiteMap' },
	{ widgetId: 4, name: 'IdirectDetails' },
	{ widgetId: 5, name: 'Idirect24HourUsage' },
	{ widgetId: 6, name: 'IdirectIPInfo' },
	{ widgetId: 7, name: 'SiteVoip' },
	{ widgetId: 8, name: 'IdirectBeamSwitches' },
	// 9-18 Deprecated
	{ widgetId: 19, name: 'Exinda' },
	{ widgetId: 20, name: 'IdirectUsageByProtocol' },
	{ widgetId: 21, name: 'SolarTransit' },
	{ widgetId: 22, name: 'SolarwindsGraphs' },
	{ widgetId: 23, name: 'IdirectHeatMap' },
	{ widgetId: 24, name: 'SolarwindsIPSLA' },
	{ widgetId: 25, name: 'Solarwinds4G' },
	{ widgetId: 26, name: 'SolarwindsComtechEbN0' },
	{ widgetId: 27, name: 'SolarwindsComtechPower' },
	{ widgetId: 28, name: 'SolarwindsComtechTemp' },
	{ widgetId: 29, name: 'SolarwindsComtechInterfaces' },
	{ widgetId: 30, name: 'SolarwindsIPSLAAverages' },
	// 31 Crew Live WiFi - discontinued
	// 32 IPS Security - discontinued?
	{ widgetId: 33, name: 'SolarwindsDailyGraph' },
	{ widgetId: 34, name: 'SolarTransitComtech' },
	{ widgetId: 35, name: 'SolarwindsNetFlow' },
	{ widgetId: 36, name: 'SiteComtechMap' },
	{ widgetId: 37, name: 'SolarwindsAvailability' },
	//	38 SolarwindsInterfaceErrors - wasn't ever used, so discontinued
	//	39-45 O3b - discontinued until SES portal availabile
	{ widgetId: 46, name: 'CiscoUmbrella' },
	{ widgetId: 47, name: 'PeplinkBandwidth' },
	{ widgetId: 48, name: 'PeplinkSignal' },
	{ widgetId: 49, name: 'AntennaPointing' },
	{ widgetId: 50, name: 'Webmonitor' },
	{ widgetId: 51, name: 'SolarwindsNetFlowNBAR' },
	{ widgetId: 52, name: 'IdirectMaritimeGraphs' },
	// { widgetId: 53, name: 'StarlinkGraphs' },
	// { widgetId: 54, name: 'StarlinkGraphs' },
	// { widgetId: 55, name: 'StarlinkGraphs' },
	// { widgetId: 56, name: 'StarlinkGraphs' },
	// { widgetId: 57, name: 'StarlinkGraphs' },
	// { widgetId: 58, name: 'StarlinkUsage' },
	// { widgetId: 59, name: 'StarlinkGraphs' },
	{ widgetId: 60, name: 'NewtecGraphs' },
	{ widgetId: 61, name: 'NewtecGraphs' },
	{ widgetId: 62, name: 'NewtecGraphs' },
	{ widgetId: 65, name: 'NewtecMap' },
	// { widgetId: 70, name: 'StarlinkDailyUsageGraph' },
	{ widgetId: 998, name: 'SiteQuota' },
];

export default {
	name: 'Site',
	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			starlinkIds: [5418,5422,5599,5604,6147,6208,6321,6326,6709,6730,6731,7065,7217,7234,7242,7347,7454,7478,7619,7631,7651,7680,7742,7960,8254,8393,8462,8533,8591,8971,9014,9236,9239,9333,9407,9413,9608,9922,9957,10022,10057,10067,10077,10085,10088,10097,10116,10128,10137,10143,10164,10174,10195,10203,10205,10206,10207,10208,10209,10210,10211,10217,10219,10233,10238,10243,10244,10245,10248,10251,10252,10256,10264,10265,10266,10267,10268,10279,10285,10288,10291,10293,10310,10322,10324,10325,10327,10348,10353,10355,10356,10357,10365,10367,10372,10382,10389,10398,10400,10405,10410,10411,10412,10413,10414,10422,10423,10425,10426,10427,10428,10444,10447,10450,10451,10453,10463,10464,10467,10469],
			loaded: false,
			loaderEnabled: false,
			updated: null,
			site: null,
			widgets: {},
			promises: {
				comtech: {},
				modemStatus: null,
			},
			error: false,
			errorMessage: false,
		};
	},
	computed: {
		...mapGetters(['layoutConfig']),
		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig('loader.logo');
		},
	},
	components: {
		ITCSpinner,
		Loader: () => import('@/view/content/loaders/Loader.vue'),
		// ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		DateRangeSelector: () => import('@/view/content/widgets/components/DateRangeSelector.vue'),
		Widget: () => import('@/view/content/widgets/site/Widget.vue'),
		IdirectModemStatus: () => import('@/view/content/widgets/site/IdirectModemStatus.vue'),
		IdirectGraphs: () => import('@/view/content/widgets/site/IdirectGraphs.vue'),
		IdirectMaritimeGraphs: () => import('@/view/content/widgets/site/IdirectMaritimeGraphs.vue'),
		SiteMap: () => import('@/view/content/widgets/site/SiteMap.vue'),
		IdirectDetails: () => import('@/view/content/widgets/site/IdirectDetails.vue'),
		Idirect24HourUsage: () => import('@/view/content/widgets/site/Idirect24HourUsage.vue'),
		IdirectIPInfo: () => import('@/view/content/widgets/site/IdirectIPInfo.vue'),
		SiteVoip: () => import('@/view/content/widgets/site/SiteVoip.vue'),
		IdirectBeamSwitches: () => import('@/view/content/widgets/site/IdirectBeamSwitches.vue'),
		Exinda: () => import('@/view/content/widgets/site/Exinda.vue'),
		IdirectUsageByProtocol: () => import('@/view/content/widgets/site/IdirectUsageByProtocol.vue'),
		SolarTransit: () => import('@/view/content/widgets/site/SolarTransit.vue'),
		IdirectHeatMap: () => import('@/view/content/widgets/site/IdirectHeatMap.vue'),
		SolarwindsGraphs: () => import('@/view/content/widgets/site/SolarwindsGraphs.vue'),
		SolarwindsIPSLA: () => import('@/view/content/widgets/site/SolarwindsIPSLA.vue'),
		SolarwindsIPSLAAverages: () => import('@/view/content/widgets/site/SolarwindsIPSLAAverages.vue'),
		Solarwinds4G: () => import('@/view/content/widgets/site/Solarwinds4G.vue'),
		SolarwindsComtechEbN0: () => import('@/view/content/widgets/site/SolarwindsComtechEbN0.vue'),
		SolarwindsComtechPower: () => import('@/view/content/widgets/site/SolarwindsComtechPower.vue'),
		SolarwindsComtechTemp: () => import('@/view/content/widgets/site/SolarwindsComtechTemp.vue'),
		SolarwindsComtechInterfaces: () => import('@/view/content/widgets/site/SolarwindsComtechInterfaces.vue'),
		SolarwindsDailyGraph: () => import('@/view/content/widgets/site/SolarwindsDailyGraph.vue'),
		SolarTransitComtech: () => import('@/view/content/widgets/site/SolarTransitComtech.vue'),
		SolarwindsNetFlow: () => import('@/view/content/widgets/site/SolarwindsNetFlow.vue'),
		SolarwindsNetFlowNBAR: () => import('@/view/content/widgets/site/SolarwindsNetFlowNBAR.vue'),
		SiteComtechMap: () => import('@/view/content/widgets/site/SiteComtechMap.vue'),
		SolarwindsAvailability: () => import('@/view/content/widgets/site/SolarwindsAvailability.vue'),
		StarlinkDailyUsageGraph: () => import('@/view/content/widgets/site/StarlinkDailyGraph.vue'),
		StarlinkGraphs: () => import('@/view/content/widgets/site/StarlinkGraphs.vue'),
		StarlinkUsage: () => import('@/view/content/widgets/site/StarlinkUsage.vue'),
		PeplinkBandwidth: () => import('@/view/content/widgets/site/PeplinkBandwidth.vue'),
		PeplinkSignal: () => import('@/view/content/widgets/site/PeplinkSignal.vue'),
		SiteQuota: () => import('@/view/content/widgets/site/SiteQuota.vue'),
		SiteTicket: () => import('@/view/content/widgets/site/SiteTicket.vue'),
		AntennaPointing: () => import('@/view/content/widgets/site/AntennaPointing.vue'),
		CiscoUmbrella: () => import('@/view/content/widgets/site/CiscoUmbrella.vue'),
		NewtecGraphs: () => import('@/view/content/widgets/site/NewtecGraphs.vue'),
		NewtecMap: () => import('@/view/content/widgets/site/NewtecMap.vue'),
		Webmonitor: () => import('@/view/content/widgets/site/SiteWebmonitor.vue'),
	},
	methods: {
		openStarlinkMonitoring() {
            window.open(`https://vsat-monitor-starlink.business.marlink.com/${this.id}/monitoring?returnUrl=https://portal.itcglobal.com/site/${this.id}`, '_blank');
        },
		setComponentProps() {
			this.updated = Date.now();
			var components = {};
			Object.keys(this.site.layout.bootlayout).forEach(r => {
				Object.keys(this.site.layout.bootlayout[r]).forEach(c => {
					var bw = this.site.layout.bootlayout[r][c];

					// ignore discontinued widgets
					// prettier-ignore
					if([9,10,11,12,13,14,15,16,17,18,31,32,38,39,40,41,42,43,44,45,53,54,55,56,57,58,59,63,64,70].includes(bw.WidgetId)){
						return [];
					}

					let widget = this.site.layout.widgets[bw.WidgetArrId];
					if (typeof widget !== 'undefined') {
						let compo = component_names.find(c => c.widgetId === widget.WidgetId);

						if (!compo) {
							components[bw.WidgetArrId] = {
								widgetId: 0,
								name: 'Widget',
								props: { props: widget },
							};
						} else {
							var comp = {
								widgetId: compo.widgetId,
								name: compo.name,
							};

							switch (comp.name) {
								// 1
								case 'IdirectModemStatus':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										deviceId: widget.DeviceId,
										network: this.site.devices[widget.DeviceId]?.stats?.data?.currentnet?.NetworkName,
										promise: this.getPromise('modemStatus'),
									};
									break;
								// 2
								case 'IdirectGraphs':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										graphs: this.site.devices[widget.DeviceId].stats?.data?.graphs,
										properties: widget?.properties,
										rates: this.site.devices[widget.DeviceId].stats?.data?.rates[
											this.site.devices[widget.DeviceId].stats?.data?.currentnet?.NetworkId
										],
									};
									break;
								// 2.1 aka 52
								case 'IdirectMaritimeGraphs':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										graphs: this.site.devices[widget.DeviceId].stats?.data?.graphs,
										// properties: widget?.properties,
										// rates: this.site.devices[widget.DeviceId].stats?.data?.rates[
										// 	this.site.devices[widget.DeviceId].stats?.data?.currentnet?.NetworkId
										// ],
									};
									break;
								// 3
								case 'SiteMap':
									comp.props = {
										deviceId: widget.DeviceId,
										devices: this.site.devices,
										statuses: this.site.status,
									};
									break;
								//4
								case 'IdirectDetails':
									comp.props = {
										device: this.site.devices[widget.DeviceId],
										widgetProps: widget.properties,
										updated: this.updated,
									};
									break;
								//5
								case 'Idirect24HourUsage':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										data: this.site.devices[widget.DeviceId].stats.data,
									};
									break;
								// 6
								case 'IdirectIPInfo':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										ipinfo: this.site.devices[widget.DeviceId].stats.data.ipinfo,
										currentnet:
											this.site.devices[widget.DeviceId].stats.data.currentnet?.NetworkId &&
											this.site.devices[widget.DeviceId].stats.data.networks.length > 1
												? this.site.devices[widget.DeviceId].stats.data.currentnet?.NetworkId
												: null,
										updated: this.updated,
									};
									break;
								// 7
								case 'SiteVoip':
									comp.props = {
										name: this.site.Name,
										id: this.id,
										tsip: this.site.tsip,
									};
									break;
								// 8
								case 'IdirectBeamSwitches':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										deviceId: widget.DeviceId,
										networks: this.site.devices[widget.DeviceId].stats.data.networks,
										updated: this.updated,
									};
									break;
								// 19
								case 'Exinda':
									comp.props = {
										id: this.site.devices[widget.DeviceId].Source,
										properties: widget?.properties,
									};
									break;
								// 20
								case 'IdirectUsageByProtocol':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										DID: this.site.devices[widget.DeviceId].Source,
										updated: this.updated,
									};
									break;
								// 21
								case 'SolarTransit':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										solarseason: this.site.devices[widget.DeviceId].stats.data.solarseason,
										solar: this.site.devices[widget.DeviceId].stats.data.solar,
										lat: this.site.devices[widget.DeviceId].stats.data.lat,
										lon: this.site.devices[widget.DeviceId].stats.data.lon,
									};
									break;
								// 22
								case 'SolarwindsGraphs':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										properties: widget?.properties,
										updated: this.updated,
									};
									break;
								// 23
								case 'IdirectHeatMap':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										DID: this.site.devices[widget.DeviceId].Source,
									};
									break;
								// 24
								case 'SolarwindsIPSLA':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										ipsla: widget.properties?.ipsla,
										properties: widget?.properties,
										devices: this.site.devices,
										updated: this.updated,
									};

									break;
								// 25
								case 'Solarwinds4G':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										updated: this.updated,
									};
									break;
								// 26
								case 'SolarwindsComtechEbN0':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										prom: this.getPromise('comtech', this.site.devices[widget.DeviceId].Source),
									};
									break;
								// 27
								case 'SolarwindsComtechPower':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										prom: this.getPromise('comtech', this.site.devices[widget.DeviceId].Source),
									};
									break;
								// 28
								case 'SolarwindsComtechTemp':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										prom: this.getPromise('comtech', this.site.devices[widget.DeviceId].Source),
									};
									break;
								// 29
								case 'SolarwindsComtechInterfaces':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										prom: this.getPromise('comtech', this.site.devices[widget.DeviceId].Source),
									};
									break;
								// 30
								case 'SolarwindsIPSLAAverages':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
									};
									break;
								// 33
								case 'SolarwindsDailyGraph':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										properties: widget?.properties,
										updated: this.updated,
									};
									break;
								// 34
								case 'SolarTransitComtech':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										properties: this.site.devices[widget.DeviceId].properties,
									};
									break;
								// 35
								case 'SolarwindsNetFlow':
									comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
									};
									break;
								// 36
								case 'SiteComtechMap':
									comp.props = {
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										properties: this.site.devices[widget.DeviceId].properties,
										widgetproperties: widget?.properties || null,
									};
									break;
								// 37
								case 'SolarwindsAvailability':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
									};
									break;
								// 46
								case 'CiscoUmbrella':
									comp.props = {
										id: widget.DeviceId,
										name: this.site.devices[widget.DeviceId].Name,
										widgetProps: widget.properties,
										source: this.site.devices[widget.DeviceId].Source,
									};
									break;
								// 47
								case 'PeplinkBandwidth':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										properties: this.site.devices[widget.DeviceId].properties,
										updated: this.updated,
									};
									break;
								// 48
								case 'PeplinkSignal':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										properties: this.site.devices[widget.DeviceId].properties,
										updated: this.updated,
									};
									break;
								// 49
								case 'AntennaPointing':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										updated: this.updated,
									};
									break;

								// 50
								case 'Webmonitor':
									comp.props = {
										id: widget.DeviceId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										updated: this.updated,
									};
									break;
								// 51
								case 'SolarwindsNetFlowNBAR':
									comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
									};
									break;
								// 52 see 2.1
								// 53-57, 59
								case 'StarlinkGraphs':
									comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										widgetId: comp.widgetId
									};
									break;
								// 58
								case 'StarlinkUsage':
								comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										widgetId: comp.widgetId
									};
									break;
								// 60-62
								case 'NewtecGraphs':
								comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										widgetId: comp.widgetId
									};
									break;
								case 'NewtecMap':
								comp.props = {
										deviceId: widget.DeviceId,
										devices: this.site.devices,
										statuses: this.site.status,
									};
									break;
								// 70
								case 'StarlinkDailyUsageGraph':
								comp.props = {
										id: widget.DeviceId,
										siteid: this.site.SiteId,
										name: widget.properties?.label || this.site.devices[widget.DeviceId].Name,
										device: this.site.devices[widget.DeviceId],
										widgetId: comp.widgetId
									};
									break;
								// 998
								case 'SiteQuota':
									comp.props = {
										quota: this.site.quota,
										quota_details: this.site.quotarr
									};
									break;
							}
							components[bw.WidgetArrId] = comp;
						}
					}
				});
			});
			return components;
		},
		getPromise(widget, source) {
			switch (widget) {
				case 'comtech':
					if (!this.promises.comtech[source]) {
						this.promises.comtech[source] = this.$http.get(`swcomtech/${source}`);
					}
					return this.promises.comtech[source];
				case 'modemStatus':
					if (!this.promises.modemStatus) {
						this.promises.modemStatus = this.$http.get(`sitestatus/${this.$route.params.id}`);
					}
					return this.promises.modemStatus;
			}
		},
		refreshPromises() {
			for (let source in this.promises.comtech) {
				this.promises.comtech[source] = this.$http.get(`swcomtech/${source}`);
			}
		},
		dateRangeChanged() {
			this.refreshPromises();
			this.load();
		},
		async load() {
			this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');
			this.loaded = false;
			this.error = false;
			let resp = await this.$http.get(`site/${this.id}`);
			this.site = resp.data.data;
			if (Object.values(this.site.devices).some(d => d.Name === null)) {
				this.error = true;
				this.errorMessage = 'This site contains an iDirect modem that is not on any NMS. Remove it from this site to clear this error.';
			}
			if (!this.site.layout.bootlayout || this.site.devices.length == 0) {
				this.error = true;
				this.errorMessage = 'This site does not contain any devices';
			} else {
				this.widgets = this.setComponentProps();
			}
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: 'Site - ' + this.site.Name,
					icon: {
						class: 'flaticon2-pen',
						route: { name: 'admineditsite', params: { id: this.id } },
						tooltip: 'Edit Site',
					},
				},
			]);
			this.loaded = true;
			this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
		},
	},
	created() {
		this.id = Number(this.id);
		this.load();
		this.$store.dispatch(GET_SITE_ALERT_BANNER, this.id);
	},
	watch: {
		async $route() {
			this.loaderEnabled = true;
			await this.load();
			this.loaderEnabled = false;
		},
	},
	beforeDestroy() {
		this.$store.commit(CLEAR_BANNER);
	},
};
</script>

<style scoped>
.error-site-link {
	color: #8dc5ff;
	text-decoration: none;
}
</style>
